@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');

body {
  background-color: #f5f5f5 !important;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

q:active {
  color: inherit;
}

.card {
  background-color: grey;
}

.stick {
  display: block;
  height: 8px;
  width: 54px;
  border-radius: 10px;
  margin-bottom: 8px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(218, 218, 218);
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

// svg pulse effect
svg {
  .pulse {
    fill: #ffe400;
    fill-opacity: 0.2;
    transform-origin: 33.45415115356445px 35.27267074584961px;
    animation-duration: 4s;
    animation-name: pulse;
    animation-iteration-count: infinite;
  }
  .scale {
    transform-origin: 33.45415115356445px 35.27267074584961px;
    animation-duration: 10s;
    animation-name: scale;
    animation-iteration-count: infinite;
  }
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pulse {
  from {
    fill-opacity: 1;
    transform: scale(0.3);
  }
  to {
    fill-opacity: 0;
    transform: scale(2.8);
  }
}

.success {
  color: green;
}

.not-verified {
  .scanner-container {
    background-color: #c80815;
  }
}

.scanner-container {
  position: relative;
  width: 100%;
  max-width: 500px;
  max-height: 500px;
  margin: 0 auto;
  padding: 0rem;
  padding-top: 100%;
  background-color: #000;
  overflow: hidden;

  .scanner-gif {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%) scale(1.2);
  }

  .success-screen {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 10;
    color: #fff;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: 0.2s;
  }
  #qr-shaded-region > div {
    transition: 0.3s;
  }

  &.wrong-code {
    // &:before {
    //   background-color: red;
    // }
    #qr-shaded-region > div {
      background-color: red !important;
    }
  }
  &.success {
    .success-screen {
      background-color: green;
      opacity: 1;
    }
  }
}
#scanner {
  z-index: 2;
  position: absolute !important;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  width: 100%;
}

.green {
  color: green;
}

.orange {
  color: orange;
  &-fill {
    fill: orange;
  }
}

.red {
  color: red;
  &-fill {
    fill: #c80815;
  }
}

.grey {
  color: grey;
  &-fill {
    fill: #868686;
  }
}

.user-total,
.user-not-verified {
  display: flex;
  padding: 8px 20px;

  .description {
    display: none;
    padding-right: 16px;
    @media all and (min-width: 768px) {
      display: block;
    }
  }
}
.user-total {
  // position: absolute;
  // left: 0;
  // top: 0;
  background-color: #2362c8;
  > * {
    color: #fff;
  }
}
.user-not-verified {
  // position: absolute;
  // bottom: 0;
  // left: 0;
  background-color: #c80815;
  > * {
    color: #fff;
  }
}

.nav-link {
  color: inherit;
  text-decoration: none;
}

.container-middle-big {
  margin: 0 auto;
  max-width: 768px;
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.link {
  text-decoration: underline;
  transition: 0.3s;

  &:hover {
    color: #4dbdc6;
  }
}

@media all and(max-width: 768px) {
  .rm-md {
    display: none;
  }
}

.form-logo {
  width: 100%;
}
